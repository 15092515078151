"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { getStripe } from "@/actions/stripe/client";
import {
  checkoutWithStripe,
  getStripePriceAmount,
} from "@/actions/stripe/server";
import { checkSpecificProductAccess } from "@/actions/user";
import { Check, ChevronRight } from "lucide-react";
import { toast } from "sonner";
import { useServerAction } from "zsa-react";

import { cn, formatPrice } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/icons";

export const CheckoutButton = ({
  priceId,
  coupon,
  className,
  mode,
  showPrice = false,
  children,
}: {
  priceId?: string;
  coupon?: string;
  mode?: "payment" | "setup" | "subscription";
  className?: string;
  showPrice?: boolean;
  children?: React.ReactNode;
}) => {
  const [loading, setLoading] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [priceInfo, setPriceInfo] = useState<{
    amount?: number;
    currency?: string;
  }>({});
  const [toltReferralId, setToltReferralId] = useState<string | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined" && (window as any).tolt_referral) {
      setToltReferralId((window as any).tolt_referral);
    }
  }, []);

  const { execute: getPrice, isPending: gettingPrice } =
    useServerAction(getStripePriceAmount);
  const { execute: checkAccess, isPending: checkingAccess } = useServerAction(
    checkSpecificProductAccess,
  );

  useEffect(() => {
    const fetchAccess = async () => {
      if (!priceId) return;
      const [data, err] = await checkAccess({ priceId });

      if (err) {
        toast.error(err.message);
        setHasAccess(false);
      } else {
        setHasAccess(data);
      }
      setLoading(false);
    };
    fetchAccess();
  }, [priceId, checkAccess]);

  useEffect(() => {
    if (!priceId) return;
    const loadPriceInfo = async () => {
      if (showPrice) {
        const [data, err] = await getPrice({ priceId });
        if (err) {
          toast.error(err.message);
          return;
        }
        if (!data.amount || !data.currency) {
          toast.error("Error fetching price information");
          return;
        }
        setPriceInfo({ amount: data.amount, currency: data.currency });
      }
    };
    loadPriceInfo();
  }, [priceId, showPrice]);

  const handleClick = async () => {
    if (!priceId) return;
    setLoading(true);
    const [data, err] = await checkoutWithStripe({
      priceId,
      coupon,
      mode,
      referralId: toltReferralId || undefined,
    });
    if (err) {
      toast.error(err.message);
      setLoading(false);
      return;
    }
    if (!data?.sessionId) {
      toast.error("Error creating checkout session " + data?.errorRedirect);
      setLoading(false);
      return;
    }

    // @ts-ignore
    fbq("initiateCheckout");

    const stripe = await getStripe();
    stripe?.redirectToCheckout({ sessionId: data?.sessionId });
    setLoading(false);
  };

  const isDisabled = showPrice
    ? loading || !priceInfo?.amount || !priceInfo?.currency
    : loading || checkingAccess || gettingPrice;

  if (hasAccess) {
    return (
      <Link href="/templates" className={cn(className)}>
        You have access
        <Check className="h-4 w-4" />
      </Link>
    );
  }

  return (
    <Button onClick={handleClick} disabled={isDisabled} className={className}>
      {isDisabled ? (
        <>
          <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
          Loading...
        </>
      ) : (
        <>
          {children}
          {showPrice && priceInfo.amount && priceInfo.currency && (
            <>
              {" - "}
              {formatPrice(priceInfo.amount, {
                currency: priceInfo.currency,
              })}
            </>
          )}
          <ChevronRight className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
        </>
      )}
    </Button>
  );
};
